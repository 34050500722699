import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import htmr from 'htmr'
import { PageHeader, Section, Container } from '../components/Common'
import { usePrivacyPolicy } from '../GraphQL/Queries'

const PrivacyPolicy = () => {
    const { policyContent: data } = usePrivacyPolicy().acf_privacyPolicy

    return (
        <Layout pageClass="privacy-policy" noHero>
            <SEO title="Privacy Policy" />
            <PageHeader title="Privacy Policy" />

            <Section>
                <Container compact>{htmr(data)}</Container>
            </Section>
        </Layout>
    )
}

export default PrivacyPolicy
